import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import FeedLogo from "../../FeedPost/component/FeedLogo";
import FeedButton from "../../FeedPost/component/FeedButton";
import {
  FlashMessage,
  Modal,
  ErrorTextHelper,
  Button,
  RichTextEditor,
  ConfirmDialog,
} from "../../../../components/common";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { rootReducersState } from "../../../../reducers";
import { ReducerFlag } from "../../../../types/reducer.types";
import {
  deletePostFlagReset,
  updatePostFlagReset,
  editFeedRequest,
  deleteFeedRequest,
} from "../../../../reducers/feeds/feedPost.reducer";
import { ReactComponent as DeleteIcon } from "../../../../assets/svg/delete-icon.svg";
import dots from "../../../../assets/svg/dots.svg";
import cameraIcon from "../../../../assets/svg/camera.svg";
import Image from "../../../../components/common/Image";
import {
  convertUTCToLocal,
  stripHTMLTags,
  uuid,
} from "../../../../utils/helper";
import history from "../../../../utils/history";
import appRoutes from "../../../../routes/app.routes";
import { openUrlInNewTab } from "../../../../utils/helper";
import Report from "../../../../components/common/Report";
import ReactPlayer from "react-player";
interface FeedListTypes {
  data: any;
  loading?: boolean;
  [key: string]: any;
  index?: any;
}

// TODO - recreate this, keep only listing part, rest take out side of the modal, recreating the each and every modal instance
const FeedBodyHeader = ({ data, index }: FeedListTypes) => {
 
  const dispatch = useDispatch();
  const {
    editLoading,
    deleteMessage,
    updateFlag,
    updateMessage,
    deleteLoading,
    deleteflag,
    deletePostType
  } = useSelector(({ postFeed }: rootReducersState) => postFeed.postFeed);
  const currentUser = useSelector(
    ({ session }: rootReducersState) => session.currentUser
  );
  const [feedMore, setFeedMore] = useState<boolean>(false);
  const [openEditFeedModal, setOpenEditFeedModal] = useState<boolean>(false);
  const [closeEditModal, setCloseEditModal] = useState<boolean>(false);
  const [openDeleteFeedModal, setOpenDeleteFeedModal] =
    useState<boolean>(false);
  const [updatedAssets, setUpdatedAssets] = useState<
    {
      id: string;
      file: Blob;
    }[]
  >([]);
  const [postMedia, setPostMedia] = useState(data.post_media);
  const [deleteMedia, setDeleteMedia] = useState<string[]>([]);
  const [postTextLength, setPostTextLength] = useState<number>(0);
  const [openReport, setOpenReport] = useState(false);
  // const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const postId = data?.id
  const form = useForm<FeedListTypes>({
    defaultValues: { post_text: data.content || data.post_text },
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = form;

  const postText = watch("post_text", "");
  const postType = data.post_type

  // Check the content length
  useEffect(() => {
    const tmpText = stripHTMLTags(postText);
    setPostTextLength(tmpText.length);
  }, [postText]);

  useEffect(() => {
    reset({ post_text: data.content });
    setPostMedia(data.post_media);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(deleteflag)) {
      if (deleteflag === ReducerFlag.SUCCESS) {
        setOpenDeleteFeedModal(false);
        history.push("/feeds");
        FlashMessage(deletePostType===1?"Article deleted successfully":"Post deleted successfully");
      } else if (deleteflag === ReducerFlag.FAILED) {
        FlashMessage(deleteMessage, "error");
      }
      resetForm();
      dispatch(deletePostFlagReset());
    }
    // eslint-disable-next-line
  }, [deleteflag]);

  useEffect(() => {
    if ([ReducerFlag.SUCCESS, ReducerFlag.FAILED].includes(updateFlag)) {
      if (updateFlag === ReducerFlag.SUCCESS) {
        setOpenEditFeedModal(false);
      } else if (updateFlag === ReducerFlag.FAILED) {
        FlashMessage(updateMessage, "error");
      }
      resetForm();
      dispatch(updatePostFlagReset());
    }
    // eslint-disable-next-line
  }, [updateFlag]);

  // useEffect(() => {
  //   if (openEditFeedModal) {
  //     const isContentChanged =
  //       stripHTMLTags(postText) !== stripHTMLTags(data.content || "");
  //     const isMediaChanged = postMedia.length !== (data.post_media?.length || 0);

  //     setIsSaveDisabled(!(isContentChanged || isMediaChanged));
  //   }
  //   // eslint-disable-next-line
  // }, [postText, postMedia, data.content, data.post_media]);


  const feedUpdateDropDown = () => {
    if (feedMore) {
      setFeedMore(false);
    } else {
      setFeedMore(true);
    }
  };

  const removeMedia = (media_id, i) => {
    const _data = (postMedia || []).filter((row) => row.id !== media_id);
    setPostMedia(_data);
    setDeleteMedia((data: string[]) => [...data, media_id]);
    setUpdatedAssets((prevState) => prevState.filter((r) => r.id !== media_id));
  };

  const onSubmit = (formData: any) => {
    let payload = new FormData();
    let i = 1;
    for (const fileObject of updatedAssets) {
      payload.append(`post_media_${i}`, fileObject.file);
      i++;
    }

    payload.append("deleted_post_media", JSON.stringify(deleteMedia));
    payload.append("post_text", formData.post_text || "");
    payload.append("id", data.id);
    dispatch(
      editFeedRequest({
        id: data.id,
        payloadData: payload,
        data: { ...data, content: formData.post_text, post_media: postMedia },
      })
    );
  };

  const onCloseEditModal = () => {
    if (
      stripHTMLTags(postText) !== stripHTMLTags(data?.content || "") ||
      postMedia.length !== data.post_media.length
    ) {
      setCloseEditModal(true);
    } else {
      setOpenEditFeedModal(false);
    }
  };

  // const onUpdateImage = async (e: any) => {
  //   const updateImageValue = e.target.files;
  //   if (postMedia?.length + updateImageValue?.length > 4) {
  //     FlashMessage("Max 4 files can be uploaded", "error");
  //     return;
  //   }

  //   Object.keys(updateImageValue).forEach((key) => {
  //     if (updateImageValue[key].type.includes("image")) {
  //       const uniqueId = uuid();
  //       setUpdatedAssets((data) => [
  //         ...data,
  //         {
  //           id: uniqueId,
  //           file: updateImageValue[key],
  //         },
  //       ]);

  //       const image_thumbnail = URL.createObjectURL(updateImageValue[key]);
  //       setPostMedia((data) => [
  //         ...data,
  //         { id: uniqueId, media_thumbnail: image_thumbnail },
  //       ]);
  //     }
  //   });
  // };

  const onUpdateMedia = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;

    // Optional limit check: 4 total uploads (images + videos)
    if ((postMedia?.length || 0) + selectedFiles.length > 4) {
      FlashMessage("Max 4 files can be uploaded", "error");
      return;
    }

    Array.from(selectedFiles).forEach((file) => {
      // Determine if it’s an image or video
      let mediaType: "image" | "video" | null = null;

      if (file.type.includes("image")) {
        mediaType = "image";
      } else if (file.type.includes("video")) {
        mediaType = "video";
      } else {
        // Skip unsupported file types
        FlashMessage("Only images or videos are allowed.", "error");
        return;
      }

      const uniqueId = uuid();

      // For the FormData submission
      setUpdatedAssets((prev) => [...prev, { id: uniqueId, file }]);

      // For preview in the UI
      const mediaThumbnail = URL.createObjectURL(file);
      setPostMedia((prev) => [
        ...prev,
        {
          id: uniqueId,
          media_thumbnail: mediaThumbnail,
          media_type: mediaType,
        },
      ]);
    });
  };

  const resetForm = () => {
    setUpdatedAssets([]);
    setDeleteMedia([]);
  };

  const handelRedirectProfile = () => {
    if (data?.is_company && data?.user_slug) {
      let redirect = "";
      if (data?.is_company === 1) {
        redirect = appRoutes.candidatePublicProfile.generateFullPath(
          data.user_slug
        );
      } else if (data?.is_company === 2) {
        redirect = appRoutes.CompanyPublicView.generatePath(data.user_slug);
      }

      if (redirect) {
        openUrlInNewTab(redirect);
      }
    }
  };
  return (
    <>
      <div className="feed-header">
        <div
          className="feed-header-info cursor-pointer"
          onClick={() => handelRedirectProfile()}
        >
          <FeedLogo profileLogo={data?.post_user_image} name={data.post_user} />
          <div className="feed-title-info">
            <h2>{data?.post_user}</h2>
            <span>{convertUTCToLocal(data.created_at)} ago</span>
          </div>
        </div>
        {currentUser?.id === data.user_id ? (
          <div
            className="feed-header-more"
            onClick={() => feedUpdateDropDown()}
          >
            <Image src={dots} alt="postImage" className="dot-icon" />
            {feedMore && (
              <ul>
                {postType === 1 ? (
                  <li onClick={() => openUrlInNewTab(appRoutes.articleUpdate.generatePath(data?.slug))}>
                    Edit
                  </li>
                ) : (
                  <li onClick={() => setOpenEditFeedModal(true)}>Edit </li>
                )}
                {/* <li>Hide Post</li> */}

                <li onClick={() => { setOpenDeleteFeedModal(true) }}>
                  Delete
                </li>
              </ul>
            )}
          </div>
        ) : (
          <div
            className="feed-header-more"
            onClick={() => feedUpdateDropDown()}
          >
            <Image src={dots} alt="postImage" className="dot-icon" />
            {feedMore && (
              <ul>
                <li onClick={() => setOpenReport(true)}>
                  Report Post
                </li>
              </ul>
            )}
          </div>
        )}

      </div>

      {openReport && (
        <Report
          openReports={true}
          referenceId={postId}
          type="post"
          onClose={() => {
            setOpenReport(false)
          }
          }
        />
      )}
      {/* Edit Feed Modal here  */}
      {openEditFeedModal && (
        <Modal
          className="feed-edit-modal"
          visible={openEditFeedModal}
          closeOnBackDrop={true}
          closeButton={true}
          size="large"
          loading={editLoading}
          title="Edit Post"
          onClose={() => onCloseEditModal()}
        >
          <div className="feed-input-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-textArea wysiwyg-data">
                <RichTextEditor
                  name="post_text"
                  validationObj={errors}
                  control={control}
                  rules={{
                    validate: {
                      validLength: (value) => {
                        const tmpText = stripHTMLTags(value || "");
                        return (
                          tmpText.length <= 3000 ||
                          "Can not exceed more then 3000 characters"
                        );
                      },
                    },
                  }}
                  useMention
                />
                <FeedLogo
                  profileLogo={data.post_user_image}
                  name={data.post_user}
                />
              </div>
              {_.get(errors, "post_text.message") && (
                <ErrorTextHelper text={_.get(errors, "post_text.message")} />
              )}
              <span className="text-length">{postTextLength}/3000</span>
              {postMedia?.length > 0 && (
                // <div className="feed-media-wrapper">
                <div className="feed-img-wrapper">
                  {postMedia?.map((media, i) => {
                    return (
                      <div className="img-wrapper" key={i}>
                        {media.media_type === "image" ? (
                          <Image
                            src={media?.media_thumbnail}
                            alt="post media"
                          />
                        ) : (
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            url={media?.media_thumbnail}
                            playing={false}
                            controls={true}
                          />
                        )}
                        <span
                          className="media-close"
                          onClick={() => removeMedia(media.id, i)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* {postMedia?.length > 0 && (
                <div className="feed-img-wrapper">
                  {postMedia?.map((image, i) => {
                    return (
                      <div className="img-wrapper" key={i}>
                        <Image src={image?.media_thumbnail} alt="post img" />
                        <span
                          className="media-close"
                          onClick={() => removeMedia(image.id, i)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    );
                  })}
                </div>
              )} */}
              <div className="feed-button-wrapper">
                <FeedButton
                  svg={cameraIcon}
                  id="edit_button"
                  name="post_media"
                  type="file"
                  form={form}
                  onUploadImage={onUpdateMedia}
                  value="post_media"
                  accept="image/png, image/gif, image/bmp, image/jpeg ,video/*"
                />
                <div className="edit-btn-wrapper">
                  <Button
                    className="submit-btn"
                    loading={editLoading}
                    disabled={
                      postText.length > 0 || postMedia.length > 0 ? false : true
                      // isSaveDisabled
                    }
                    type="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className="submit-btn"
                    color="secondary"
                    onClick={() => onCloseEditModal()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      )}

      {/* Edit Feed Modal ends here  */}

      {/* Edit Feed Confirm Modal here */}
      {closeEditModal && (
        <Modal
          className="feed-edit-modal feed-delete-modal dicard-modal "
          visible={true}
          closeOnBackDrop={true}
          closeButton={true}
          size="medium"
          loading={editLoading}
          title="Discard Feed"
          onClose={() => setCloseEditModal(false)}
        >
          <p>
            You have unsaved changes. Are you sure you want to discard changes?
          </p>
          <Button
            color="primary"
            className="submit-btn"
            onClick={() => setCloseEditModal(false)}
          >
            Keep Posting
          </Button>
          <Button
            color="secondary"
            className="submit-btn"
            onClick={() => {
              setCloseEditModal(false);
              setOpenEditFeedModal(false);
              setPostMedia(data.post_media);
            }}
          >
            Discard
          </Button>
        </Modal>
      )}

      {/* Edit Feed Confirm Modal ends here */}

      {/* Delete Feed Confirm Modal here */}
      <ConfirmDialog
        visible={openDeleteFeedModal}
        title={postType === 1 ? "Remove article" : "Remove post"}
        onCancel={() => setOpenDeleteFeedModal(false)}
        bodyText={postType === 1 ? "Do you really want to remove the article?" : "Do you really want to remove the post?"}
        onConfirm={() => {
          dispatch(deleteFeedRequest(data.id));
          setOpenDeleteFeedModal(false);
        }}
        loading={deleteLoading}
        confirmText="Confirm"
      />
      {/* Delete Feed Confirm Modal ends here */}
    </>
  );
};

export default FeedBodyHeader;
